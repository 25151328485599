/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.match-height').matchHeight();
        $('.match-height-2').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // const el = document.querySelector('table#product-variations-table tbody tr td.table-add-to-cart-btn a');
  // console.log(el);  

 
  // const priceFilterHandles = document.querySelectorAll('.ui-slider-handle');

  //   priceFilterHandles.forEach( handle => {
  //     handle.onchange = function() {
  //       console.log("has changed!");
  //     };
  //   });


  $(document).ready( function () {
    $('#product-variations-table').DataTable({
      responsive: true,
      language: {
        "sEmptyTable":     "Aucune donnée disponible dans le tableau",
        "sInfo":           "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
        "sInfoEmpty":      "Affichage de l'élément 0 à 0 sur 0 élément",
        "sInfoFiltered":   "(filtré à partir de _MAX_ éléments au total)",
        "sInfoPostFix":    "",
        "sInfoThousands":  ",",
        "sLengthMenu":     "Afficher _MENU_ éléments",
        "sLoadingRecords": "Chargement...",
        "sProcessing":     "Traitement...",
        "sSearch":         "Rechercher :",
        "sZeroRecords":    "Aucun élément correspondant trouvé",
        "oPaginate": {
            "sFirst":    "Premier",
            "sLast":     "Dernier",
            "sNext":     "Suivant",
            "sPrevious": "Précédent"
        },
        "oAria": {
            "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
            "sSortDescending": ": activer pour trier la colonne par ordre décroissant"
        },
        "select": {
                "rows": {
                    "_": "%d lignes sélectionnées",
                    "0": "Aucune ligne sélectionnée",
                    "1": "1 ligne sélectionnée"
                } 
        }
      }
    });
  });

  function changeBottomMenuLocation() {
    const headerBottom = document.getElementById('header-bottom');
    const menuItems = document.querySelector('.menu-items');
    const nav = document.getElementById('navbar');

    if (window.matchMedia("(max-width: 750px)").matches ) {
      menuItems.appendChild(headerBottom);
      headerBottom.classList.add('header-bottom-responsive');
    } else {
      // menuItems.removeChild(headerBottom);
      nav.insertAdjacentElement('afterend', headerBottom);
      headerBottom.classList.remove('header-bottom-responsive');
    }
  }



  changeBottomMenuLocation();



  window.addEventListener('resize', function() {
    changeBottomMenuLocation();
  });

  

  // $(document).ready(function() {
  //   $( "#range" ).slider({
  //     range: true,
  //     min: 10,
  //     max: 999,
  //     values: [ 200, 500 ],
  //     slide: function( event, ui ) {
  //    $( "#price" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
  //    }
  // });
      
  // $( "#price" ).val( "$" + $( "#range" ).slider( "values", 0 ) +
  //          " - $" + $( "#range" ).slider( "values", 1 ) );
      
  //   });
  
})(jQuery); // Fully reference jQuery after this point.
